import enterpriseApi from '@/api/enterprise.api'
import fleetApi from '@/api/fleet.api'
import harvestFrontCurrentStateApi from '@/api/harvestFrontCurrentState.api'
import thingApi from '@/api/thing.api'
import { configurationService } from '@/business/configurationService'
import filterService from '@/business/filter.service'
import { smartTruckDispatcherService } from '@/business/smartTruckDispatcherService'
import CategoryDialogComponent from '@/components/category/CategoryDialogComponent.vue'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import DeviationDialogComponent from '@/components/deviation/DeviationDialogComponent.vue'
import ShiftSummaryDialogComponent from '@/components/shift-summary/ShiftSummaryDialogComponent.vue'
import TruckDispatchComponent from '@/components/smart-truck-dispatcher/TruckDispatch/TruckDispatchComponent.vue'
import FrontSelectorDialogComponent from '@/components/smart-truck-dispatcher/front-selector-dialog/FrontSelectorDialogComponent.vue'
import HarvesterDialogComponent from '@/components/smart-truck-dispatcher/harvester-dialog/HarvesterDialogComponent.vue'
import TruckDialogComponent from '@/components/smart-truck-dispatcher/truck-dialog/TruckDialogComponent.vue'
import FrontDialogComponent from '@/components/smart-truck-dispatcher/front-dialog/FrontDialogComponent.vue'
import TruckDispatchDialogComponent from '@/components/smart-truck-dispatcher/truck-dispatch-dialog/TruckDispatchDialogComponent.vue'
import TrucksAssignmentComponent from '@/components/smart-truck-dispatcher/trucks-assignment-dialog/TrucksAssignmentComponent.vue'
import WaitEmptyTrucksComponent from '@/components/smart-truck-dispatcher/wait-emtpy-trucks/WaitEmptyTrucksComponent.vue'
import { SnackbarStyle } from '@/constants/constants'
import ConfigurationsConstants from '@/constants/smartTruckDispatcher.constants'
import i18n from '@/i18n'
import { STATE_TYPE_KEY, TYPE_KEY } from '@colven/common-domain-lib/lib'
import { mapActions, mapMutations } from 'vuex'
import stateService from '@/business/state.service';
import driveUnitApi from '@/api/drive-unit.api'

export default {
  name: 'SmartTruckDispatcherComponent',
  components: {
    TruckDispatchComponent,
    AutocompleteComponent,
    TrucksAssignmentComponent,
    CategoryDialogComponent,
    FrontSelectorDialogComponent,
    DeviationDialogComponent,
    WaitEmptyTrucksComponent,
    ShiftSummaryDialogComponent,
    TruckDialogComponent,
    HarvesterDialogComponent,
    TruckDispatchDialogComponent,
    FrontDialogComponent
  },
  data: () => ({
    pinnedFrontsConfigurationsId: ConfigurationsConstants.ConfigurationKeys.PINNED_FRONTS_KEY,
    orderConfigurationsId: ConfigurationsConstants.ConfigurationKeys.FRONTS_ORDER_KEY,
    selectedFrontconfigurationsId: ConfigurationsConstants.ConfigurationKeys.SELECTED_FRONTS_KEY,
    deviationTimesKey: ConfigurationsConstants.HARVEST_FRONT_DEVIATION_TIMES_KEY,

    showTruckDialog: false,
    infoTruckModal: null,
    currentFront: null,
    showFrontDialog: null,

    showHarvesterDialog: false,
    infoHarvestModal: null,

    loadingTable: false,
    drawer: false,
    workFrontFilter: {
      id: 'workFront',
      name: i18n.t('headers.workFront'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectAction: undefined
    },
    categoryDialogModel: {
      show: false,
    },
    showFrontsSelectorDialog: false,
    deviationDialogModel: {
      show: false,
    },
    shiftSummaryDialogModel: {
      show: false,
    },
    selectedTab: 'tab-summary',
    timeFormat: null,
    filterSwitch: true,
    dateAndTimeRange: TimeRanges.LAST_HOUR,
    //dateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    autoReport: false,
    thingsMap: new Map(),
    timeStampFrom: null,
    timeStampTo: null,
    availableTrucks: [],
    availableTrucksToDispatch: [],
    waitingEmptyTrucks: [],
    harvestFronts: [],
    pinnedFronts: [],
    configurations: null,
    orderConfigurations: null,
    showTrucksAssignmentDialog: false,
    selectedTruck: null,
    harvestFrontMap: new Map(),
    fleetMap: new Map(),
    categoryMap: new Map(),
    showTruckDispatchDialog: false,
    currentFrontToDispatch: null,
    selectedFronts: [],
    statesTypesMap: new Map(),
    pims: {
      cane: '',
      grinding: ''
    },
    randomkey: Math.ceil(Math.random() * 1000000),
    refreshTrucksSelector: 0,
    categories: [],
    deviationTime: null,
    randomkeyNewFront: Math.ceil(Math.random() * 1000000),
    driveUnits: [],
    trucksIncludedInOtherFrontsIds: [],
  }),
  computed: {
  },
  async created() {
    const selectedEnterprise = JSON.parse(localStorage.getItem('enterpriseId'))
    const fleets = await fleetApi.getAll();
    const categories = await enterpriseApi.getCategoriesPaginated(null, null, selectedEnterprise);
    const things = await thingApi.getThingByTypeKey(TYPE_KEY.TRUCK);
    this.driveUnits = await driveUnitApi.getByEnterprise();
    this.updateFleetMap(fleets);
    this.updateCategoryMap(categories.data);
    this.updateThingsMap(things);

  },
  beforeDestroy() {
    clearInterval(this.unitIdInteval)
  },
  async mounted() {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    await this.getConfiguration();
    await this.getStateTypes();
    await this.initHarvestFrontMap();
    await this.getData();
    this.unitIdInteval = setInterval(async () => {
      await this.refreshInfo();
    }, 5000);
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    async getStateTypes() {
      const statesTypesResponse = await stateService.getStatesTypesByEnterpriseId();
      let lang = localStorage.getItem('locale') || i18n.locale.split('-')[0]
      for (const statesTypes of statesTypesResponse) {
        this.statesTypesMap.set(statesTypes.key, statesTypes.name[lang]);
      }
    },

    updateFleetMap(fleets) {
      if (fleets && fleets.length > 0) {
        for (const fleet of fleets) {
          this.fleetMap.set(fleet._id, fleet.name);
        }
      }
    },
    updateCategoryMap({ data: categories }) {
      if (categories && categories.length > 0) {
        for (const category of categories) {
          this.categoryMap.set(category._id, category.name);
        }
      }
    },
    updateThingsMap({ data: things }) {
      if (things && things.length > 0) {
        for (const thing of things) {
          this.thingsMap.set(thing._id, thing.name);
        }
      }
    },
    async refreshInfo() {
      await this.getData();
    },
    async initHarvestFrontMap() {
      this.harvestFrontMap = new Map();
      const harvestFronts = await filterService.getAllHarvestFront();
      if (harvestFronts && harvestFronts.length > 0) {
        for (const harvestFront of harvestFronts) {
          if (this.selectedFronts.includes(harvestFront.id)) {
            this.harvestFrontMap.set(harvestFront.id, harvestFront.name);
          }
        }
      }
    },
    showTruckDialogfunction(infoTruckModal, currentFront) {
      this.infoTruckModal = infoTruckModal;
      this.currentFront = currentFront;
      this.categoriesFronts = this.harvestFronts.filter(front => front.category._id === infoTruckModal.category._id)
      this.showTruckDialog = true
    },
    closeTruckDialog() {
      this.showTruckDialog = false
    },

    showFrontDialogfunction(currentFront) {
      this.currentFront = currentFront;
      this.showFrontDialog = true
    },
    closeFrontDialog() {
      this.showFrontDialog = false
    },

    showHarvestDialogFunction(infoHarvestModal, currentFront) {
      this.infoHarvestModal = infoHarvestModal;
      this.currentFront = currentFront;
      this.showHarvesterDialog = true
    },
    closeHarvesterDialog() {
      this.showHarvesterDialog = false
    },

    openDialogCategoryAction() {
      this.categoryDialogModel.show = true
    },
    cancelDialogCategoryAction() {
      this.categoryDialogModel.show = false
    },
    openDialogDeviationAction() {
      this.deviationDialogModel.show = true
    },
    cancelDialogDeviationAction() {
      this.deviationDialogModel.show = false
    },
    async saveDialogDeviationAction(deviationTimes) {
      await harvestFrontCurrentStateApi.saveDeviationTimes(deviationTimes).then(() => {
        this.deviationDialogModel.show = false
        this.showSnackbar({ visible: true, text: this.$t('deviation.updateSuccess'), timeout: 9500, style: SnackbarStyle.SUCCESS })
      })
      this.getData(true)
    },
    openDialogShiftSummaryAction() {
      this.shiftSummaryDialogModel.show = true
    },
    cancelDialogShiftSummaryAction() {
      this.shiftSummaryDialogModel.show = false
    },
    async getShiftSummaryReportAction() {
      this.shiftSummaryDialogModel.show = false
    },

    openDialogFrontsSelector() {
      this.showFrontsSelectorDialog = true;
    },
    async cancelDialogFrontsSelector() {
      this.showFrontsSelectorDialog = false;
      await this.getConfiguration();
      await this.initHarvestFrontMap();
      this.refreshInfo();
      //recargar los frentes que debería mostrar.
    },
    async getData() {
      this.loadingTable = true;
      try {
        //traigo los camiones asignados a canchon con categoria y status
        this.availableTrucks = await smartTruckDispatcherService.getThingsData();
        this.waitingEmptyTrucks = this.getWaitingEmptyTrucks(this.availableTrucks);
        const selectedEnterprise = JSON.parse(localStorage.getItem('enterpriseId'))
        this.categories = (await enterpriseApi.getCategoriesPaginated(0, 0, selectedEnterprise)).data.data;
        const harvestFrontStatus = await smartTruckDispatcherService.getHarvestFrontStatus(Array.from(this.harvestFrontMap.keys()));
        const harvestFrontCount = this.harvestFronts.length;
        this.harvestFronts = smartTruckDispatcherService.parseHarvestFrontsStatus(harvestFrontStatus, this.harvestFrontMap, this.availableTrucks, this.categories, this.statesTypesMap, this.deviationTime, this.driveUnits);
        if (harvestFrontCount !== this.harvestFronts.length) {
          this.randomkeyNewFront = Math.ceil(Math.random() * 1000000);
        }
        this.availableTrucksToDispatch = this.getAvailableTrucksToDispatch(this.availableTrucks);
        this.getTrucksDispatched();
        this.getPimsData();
        this.applyConfigurations();
      } finally {
        this.loadingTable = false;
        this.randomkey = Math.ceil(Math.random() * 1000000);
      }
    },
    getPimsData() {
      const caneQty = 0;
      const grindingQty = 0;
      this.pims = {
        cane: caneQty ? caneQty : 'Sin datos',
        grinding: grindingQty ? grindingQty : 'Sin datos'
      }
    },
    getWaitingEmptyTrucks(trucks) {
      return trucks.filter(truck => truck.state && truck.state.type && truck.state.type.key === ConfigurationsConstants.STATUS_ESPERA_VACIO && (!truck.trackData || !truck.trackData.coupledThings));
    },
    getTrucksDispatched() {
      if (!this.harvestFronts || !this.harvestFronts.length) {
        return;
      }
      const trucksIncludedInOtherFronts = this.harvestFronts.flatMap(front => front.trucks)
      this.trucksIncludedInOtherFrontsIds = trucksIncludedInOtherFronts.map(truck => truck.idThing)
    },
    getAvailableTrucksToDispatch(trucks) {
      return trucks.filter(truck => truck.state && truck.state.type && truck.state.type.key !== STATE_TYPE_KEY.FUERA_SERVICIO);
    },
    /*
    Ordenar los frentes de cosecha segun los criterios
    */
    sortData() {
      this.harvestFronts.sort((a, b) => this.compareFn(a, b))
    },
    compareFn(a, b) {//funcion que compara 2 frentes para ordenarlos
      //primero compara por el campo pinned, para que los frentes fijados vayan al pie de la lista
      if (a.pinned > b.pinned) return 1
      if (a.pinned < b.pinned) return -1

      else { //despues compara por los campos nombre y categoría
        //me fijo si  el usuario tiene configurado filtros
        if (this.orderConfigurations) {
          if (this.orderConfigurations.orderBy === ConfigurationsConstants.Orders.NAME_ASCENDING) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
          }
          else if (this.orderConfigurations.orderBy === ConfigurationsConstants.Orders.NAME_DESCENDING) {
            if (a.name < b.name) return 1
            if (a.name > b.name) return -1
          }
          else if (this.orderConfigurations.orderBy === ConfigurationsConstants.Orders.CATEGORY_ASCENDING) {
            if (a.category.name > b.category.name) return 1
            if (a.category.name < b.category.name) return -1
          }
          else {
            if (a.category.name < b.category.name) return 1
            if (a.category.name > b.category.name) return -1
          }
          return 0;
        }
        else {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0;
        }
      }
    },
    async pinFront(data) {
      if (this.pinnedFronts.some(pf => pf === data)) {
        const index = this.pinnedFronts.indexOf(data, 0);
        this.pinnedFronts.splice(index, 1);
      } else {
        this.pinnedFronts.push(data);
      }
      await this.saveConfiguration();
      this.sortData();
    },

    async getConfiguration() {
      if (!this.configurations) {
        const config = await configurationService.get(this.pinnedFrontsConfigurationsId)
        if (config) {
          this.configurations = config;
          if (config.data.pinnedFronts) {
            this.pinnedFronts = config.data.pinnedFronts
          }
        }
      }
      if (!this.deviationTime) {
        this.deviationTime = await harvestFrontCurrentStateApi.getConfiguration(this.deviationTimesKey)
      }

      const orderConfig = await configurationService.get(this.orderConfigurationsId)
      if (orderConfig) {
        this.orderConfigurations = orderConfig.data;
      }
      const selectedFrontConfig = await configurationService.get(this.selectedFrontconfigurationsId)
      if (selectedFrontConfig) {
        this.configurations = selectedFrontConfig;
        if (selectedFrontConfig.data.selectedFronts) {
          this.selectedFronts = selectedFrontConfig.data.selectedFronts
        }
      }
    },
    async saveConfiguration() {
      await configurationService.save(this.pinnedFrontsConfigurationsId, {
        pinnedFronts: this.pinnedFronts
      })
    },
    applyConfigurations() {
      this.applyPinnedFrontsConfigurations();
      this.selectRecomendedFront();
      this.sortData();
    },
    applyPinnedFrontsConfigurations() {
      if (this.configurations) {
        this.harvestFronts.map(hf => { if (this.pinnedFronts.some(pf => pf === hf.id)) { hf.pinned = true } });
      }
    },
    showTrucksAssignment() {
      this.showTrucksAssignmentDialog = true;
    },
    closeTrucksAssignmentDialog() {
      this.showTrucksAssignmentDialog = false;
    },
    notImplemented() {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
    availableTruckSelectedFunction(selectedTruck) {
      this.selectedTruck = selectedTruck;
      this.selectRecomendedFront();
    },
    selectRecomendedFront() {
      this.harvestFronts.map(harvestFront => harvestFront.highlited = false);
      if (this.selectedTruck) {
        //paso el arreglo a una copia para no desordenar la referencia
        const harvestFrontsCopy = this.harvestFronts.slice(0)
        let actualCategoryFronts = harvestFrontsCopy.filter(harvestFront => harvestFront.category._id === this.selectedTruck.category._id);
        if (!actualCategoryFronts.length) {
          return;
        }

        actualCategoryFronts.sort((a, b) => {
          if (a.frontTimer.time < b.frontTimer.time) {
            return 1
          }
          if (a.frontTimer.time > b.frontTimer.time) {
            return -1
          }
          return 0
        })

        if (actualCategoryFronts.length > 0) {

          this.harvestFronts.map(harvestFront => { if (harvestFront === actualCategoryFronts[0]) harvestFront.highlited = true });
        }
      }

    },
    dispatchTruckOpenDialog(front) {
      if (!front) return
      this.currentFrontToDispatch = front;
      this.showTruckDispatchDialog = true;
    },
    dispatchTruckCloseDialog() {
      this.showTruckDispatchDialog = false;
      this.currentFrontToDispatch = null;
      this.selectedTruck = null;
      this.refreshTrucksSelector = Math.ceil(Math.random() * 1000000);
      this.selectRecomendedFront();
    },
  },
  watch: {

  }
}
